import React from 'react'
import Layout from '../components/layout'
import Scrollable from '../components/Scrollable'
import PageHeader from '../components/PageHeader'
import Recipe from '../components/Recipe'
import Footer from '../components/Footer'
import { TranslationConstants } from '../Constants'


export default () => {
    const blue = '#1d3a6d';
    return (
        <Layout>
            <Scrollable>
                <PageHeader headercenter={TranslationConstants.recipes} color={blue} subheader={TranslationConstants.tryAtHome} />
                <Recipe />
                <Footer />
            </Scrollable>
        </Layout>

    )

}