import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import style from './style.module.scss'
import { ProductCell, LoadingView } from '../'

const Recipe = (props) => {

    let [isLoaded, toggleLoading] = useState(false);
    useEffect(() => {
        let iterator = 0;
        toggleLoading(false)

        props.recipes.forEach((item, key) => {

            //Destructure the photos array OR the photo object. One of them will be empty so we set defaults.
            let { photos = [], photo = {} } = item;

            //Get the url of the image...
            let url = photos[0] ? photos[0].location || null : null;

            //If the image is null then just iterate over it.
            if (url == null) iterator++;
            else {
                //Start loading the image...
                var img = new Image();
                img.src = url;
                img.onload = () => {
                    iterator++;
                    if (iterator == props.recipes.length) toggleLoading(true)
                }
            }
        })
    }, [props])

    return (
        <div className={style.row} style={{ background: props.background ? `${props.background}` : null }}>
            <div className={style.productsWrapper}>
                {
                    !props.getRecipesLoading && isLoaded ? props.recipes.map((item, key) => <ProductCell recipe index={key} theme={'blue'} key={key} id={key} data={item} />) : <LoadingView label="recipes" />
                }
            </div>
        </div>
    )
}

Recipe.propTypes = {
    background: PropTypes.string
}

Recipe.defaultProps = {
    background: null
}

export default connect((state, props) => {
    return {
        getRecipesLoading: state.getRecipesLoading,
        getRecipesFailed: state.getRecipesFailed,
        recipes: state.recipes
    }
})(Recipe)